@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl font-bold mt-10 mb-4;
  }
  h2 {
    @apply text-2xl font-bold mt-10 mb-4;
  }
  h3 {
    @apply text-xl font-bold mt-10 mb-4;
  }
  h4 {
    @apply text-lg font-bold mt-10 mb-4;
  }
  a {
    @apply underline decoration-yellow-400 hover:decoration-yellow-500
  }
  .dropDown {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 inline w-32 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
  }
}

body {
  margin: 0;
  font-family: "Work Sans", 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.test {
color: red;
}