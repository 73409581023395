.App {
  text-align: center;
}
#contentWrapper {
  min-height: calc(100vh - 84px);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#skillChartWrapper path, #skillChartWrapper .chartGrid line, #skillChartWrapper .tick line{
 stroke: #bfdbfe;
}

#skillChartWrapper .tooltip {
  width: 160px;
    height: 160px;
    border-radius: 6px;
    background-color: rgba(254, 240, 138, 0.85);
    padding: 6px;
    transition: transform all 600ms ease-out;
    position: absolute;
    border: 4px solid #fde047;
    font-size: 90%;
}
#skillChartWrapper .tooltip p.header {
  padding-bottom: 5px; 
  margin-bottom: 5px; 
  border-bottom: 1px solid black;
  font-weight: 500;
}

.x-axis .domain, .y-axis .domain {
  fill: none;
  stroke: #bfdbfe;
  shape-rendering: crispEdges;
  stroke-width: 4px;
}

.overlay {
  opacity: 0;
}
.focus {
  fill: rgba(250, 204, 21, 0.9);
}

#stockWrapper {
  position: relative;
  color: white;
}
#stockWrapper .details {
  position: absolute;
  top: 1rem;
  left: 1rem; 
}
#stockWrapper .details span.label {
  display: inline-block;
  width: 50px;
}
#stockWrapper .focus {
  fill: rgba(139, 92, 246, 0.9);
}

/* #stockWrapper svg .stock-high path{
 stroke: #4ade80;
}
#stockWrapper svg .stock-low path{
 stroke: #ef4444;
} */
/* #stockWrapper svg .stock-c path{
 stroke: blue;
} */

#stockWrapper svg .chartGrid line, #stockWrapper svg .tick line,#stockWrapper svg .domain, #stockWrapper svg .x-axis .domain, #stockWrapper svg .y-axis .domain{
 stroke: #334155;
}
#stockWrapper text {
  fill: #f8fafc;
  font-weight: bold;
}
#stockWrapper .x-axis .domain, #stockWrapper .y-axis .domain {
  stroke-width: 2px;
}

#natureIcons {
  animation: fadeInUp 500ms;
  /* animation-delay: calc(var(--order)*200ms) */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}